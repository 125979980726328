<template>
    <v-stepper v-model="stepNo">
        <v-stepper-header>
            <template v-for="(title, index) in titles">
                <v-stepper-step :complete="stepNo > index + 1" :step="index + 1" :key="'step'+index">{{title}}</v-stepper-step>
                <v-divider v-if="index !== titles.length - 1" :key="'devider'+index"></v-divider>
            </template>
        </v-stepper-header>
        <v-stepper-items>
            <v-stepper-content step="1">
                <v-flex xs12 sm8 md4>
                    <div class="text-xs-center">
                        <div class="outlayer">
                            <div class="size">
                                <img src="@/assets/clerk-scan.svg" />
                            </div>
                        </div>
                        <v-alert :value="true" type="warning">{{ $t('clerkPutIn.scan-bag-hint') }}</v-alert>
                        <p class="subheading mt-3">{{ $t('clerkPutIn.til-screen-show-scan-ok') }}</p>
                        <v-btn large block class="subheading" color="primary" @click="setStepNo(2)">{{ $t('clerkPutIn.next') }}</v-btn>
                    </div>
                </v-flex>
            </v-stepper-content>
            <v-stepper-content step="2">
                <QRReader :camera="camera" @query="onQuery"></QRReader>
            </v-stepper-content>
            <v-stepper-content step="3" class="pt-1">
                <v-layout justify-center>
                    <v-flex xs12 sm10 md8 lg8>
                        <door-selector
                            :enable="stepNo === 3"
                            :box-id="boxId"
                            :door-id.sync="doorId"
                            @select="selectDoorByStaff"
                            @back="setStepNo(2)"
                        ></door-selector>
                    </v-flex>
                </v-layout>
            </v-stepper-content>
            <v-stepper-content step="4">
                <v-layout justify-center>
                    <v-flex xs12 sm8 md4>
                        <v-chip label color="green" text-color="white" class="title">
                            儲格&nbsp;&nbsp;<b>{{doorId}}</b>
                        </v-chip>
                        <v-form class="mt-2" ref="form" v-model="receiver.valid">
                            <v-text-field outline clearable autofocus prepend-icon="price_check" name="fee" label="加收金額" type="number"
                                          v-model="receiver.amount" data-cy="amountField">
                            </v-text-field>
                            <v-text-field outline clearable prepend-icon="description" name="note" label="告知說明"
                                          type="text" v-model="receiver.note" data-cy="noteField">
                            </v-text-field>
                        </v-form>
                        <v-layout justify-center>
                            <v-btn flat large block color="primary" @click="setStepNo(3)">
                                <v-icon>arrow_back_ios</v-icon>上一步
                            </v-btn>
                            <v-btn large block color="primary" @click="controlDoor" data-cy="controlDoorBtn">使用儲格</v-btn>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-stepper-content>
            <v-stepper-content step="5">
                <v-layout justify-center>
                    <v-flex xs12 sm8 md4>
                        <v-list subheader>
                            <v-list-tile>
                                <v-list-tile-content>
                                    <v-list-tile-title class="subheading">儲格編號&nbsp;&nbsp;<b>{{doorId}}</b></v-list-tile-title>
                                </v-list-tile-content>
                                <v-list-tile-action>
                                    <v-btn dark depressed color="cyan" class="subheading" @click="reopen" data-cy="reopenBtn">關門</v-btn>
                                </v-list-tile-action>
                            </v-list-tile>
                            <v-list-tile>
                                <v-list-tile-content>
                                    <v-list-tile-title class="subheading">加收金額&nbsp;&nbsp;<b>{{receiver.amount}}</b></v-list-tile-title>
                                </v-list-tile-content>
                            </v-list-tile>
                            <v-list-tile>
                                <v-list-tile-content>
                                    <v-list-tile-title class="subheading">告知說明&nbsp;&nbsp;<b>{{receiver.note}}</b></v-list-tile-title>
                                </v-list-tile-content>
                            </v-list-tile>
                        </v-list>
                        <v-alert :value="true" type="warning" class="subheading">{{ $t('putIn.close-door') }}</v-alert>
                    </v-flex>
                </v-layout>
                <v-layout justify-center>
                    <v-btn large block class="ma-1" color="primary" @click="leave()" data-cy="leaveBtn">{{$t('putIn.done')}}</v-btn>
                </v-layout>
            </v-stepper-content>
        </v-stepper-items>
        <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err" :timeout="dialog.timeout"></waiting-dialog>
        <v-dialog persistent v-model="emptyDialog" width="250">
            <v-card class="text-xs-center">
                <v-card-text  class="title">
                    {{ warnMsg }}
                </v-card-text>
                <v-card-actions class="justify-center">
                    <v-btn block class="subheading" color="orange" @click="close()"> {{ $t('QRReader.close') }} </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-stepper>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import BoxIdWatcherMixin from '@/mixins/BoxIdWatcher'
import ControlResultWatcher from '@/mixins/ControlResultWatcher'
import PutinMixin from '@/mixins/PutinMixin'
import { HostUrl } from '@/store'
import { getAxiosConfig } from '@/utils/AuthService'
import i18n from '@/i18n'

export default {
    name: 'washClerkPutin',
    mixins: [PutinMixin, BoxIdWatcherMixin, ControlResultWatcher],
    data() {
        store.commit('setPageHome', 2)
        return {
            titles: ['掃描實體袋上條碼', '店員掃描 QR Code', '選擇儲格', '加收說明', '放入包裹'],
            stepNo: 1,
            camera: 'off',
            receiver: {
                valid: false,
                amount: 0,
                note: ''
            },
            emptyDialog: false,
            warnMsg: null
        }
    },
    methods: {
        initReceiver() {
            this.receiver = {
                valid: false,
                amount: 0,
                note: ''
            }
        },
        init() {
            store.commit('setTargetBoxId', '')
            this.camera = 'off'
            this.boxId = ''
            this.stepNo = 1
            this.trackNo = null
            this.doorId = 0
            this.initReceiver()
            this.emptyDialog = false
            this.warnMsg = null
        },
        setStepNo: function(no) {
            console.log('[clerk putin] step:'+no+',boxid:'+this.boxId+',camera:'+this.camera)
            if (no === 1) this.init();
            else if (no == 2 & this.boxId === '') this.camera = 'auto'
            else this.camera = 'off'
            this.stepNo = no;
            store.commit('setTitle', this.titles[this.stepNo - 1]);
            this.closeDialog()
        },
        selectDoorByStaff(door, rate, isAvailable) {
            this.selectDoor(door, rate)
            if(isAvailable) this.nextStep()
        },
        async controlDoor() {
            this.emptyDialog = false
            this.warnMsg = null
            this.showDialog('資料處理中', 'TIMEOUT: 請掃描帶子上條碼後，再試試看')
            let box_id = store.getters.targetBoxId
            try {
                let response = await axios.post(HostUrl + '/api/box/' + box_id + '/washclerk/putin', {
                    door_id: this.doorId,
                    amount: this.receiver.amount,
                    note: this.receiver.note,
                    client_id: store.getters.clientId
                }, getAxiosConfig());
                if (response.data.code == 0) {
                    this.trackNo = response.data.track_no;
                }
                else {
                    this.emptyDialog = true
                    this.warnMsg = response.data.message
                    this.closeDialog()
                }
            } catch (error) {
                this.emptyDialog = true
                this.warnMsg = '其他錯誤: '+ error
                this.closeDialog()
            }
        },
        close() {
            this.emptyDialog = false
            this.warnMsg = null
            this.setStepNo(1)
        },
        leave() {
            this.init();
            this.$router.push(`/${i18n.locale}/washhost/menu`);
        }
    }
};
</script>

<style scoped>
</style>
